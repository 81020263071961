import "es6-promise/auto";
import images from './images';
import lazysizes from 'lazysizes';
import Swiper from 'swiper';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    const vm = new Vue.default({
        el: "#site",
        delimiters: ['${', '}'],
        components: {
            'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
        },
        data: {
            'mobileClass': false,
            'modal' : false
        },
        methods: {
            toggleModal: function() {
                this.modal = !this.modal;
                if (this.modal) {
                    document.getElementsByTagName("html")[0].setAttribute("class", "overflow-hidden");
                    document.getElementsByTagName("body")[0].setAttribute("class", "overflow-hidden");
                    document.getElementById("modalhome").classList.remove('hidden');
                    document.getElementById("modalhome").classList.add('flex');
                } else {
                    document.getElementsByTagName("html")[0].setAttribute("class", "");
                    document.getElementsByTagName("body")[0].setAttribute("class", "");
                    document.getElementById("modalhome").classList.add('hidden');
                    document.getElementById("modalhome").classList.remove('flex');
                }
            },
            toggleModal2: function() {
                this.modal = !this.modal;
                if (this.modal) {
                    document.getElementsByTagName("html")[0].setAttribute("class", "overflow-hidden");
                    document.getElementsByTagName("body")[0].setAttribute("class", "overflow-hidden");
                    document.getElementById("modalhome2").classList.remove('hidden');
                    document.getElementById("modalhome2").classList.add('flex');
                } else {
                    document.getElementsByTagName("html")[0].setAttribute("class", "");
                    document.getElementsByTagName("body")[0].setAttribute("class", "");
                    document.getElementById("modalhome2").classList.add('hidden');
                    document.getElementById("modalhome2").classList.remove('flex');
                }
            },

            openMobileNav: function() {
                this.mobileClass = !this.mobileClass;
                if (this.mobileClass) {
                    document.getElementsByTagName("html")[0].setAttribute("class", "overflow-hidden");
                    document.getElementsByTagName("body")[0].setAttribute("class", "overflow-hidden");
                } else {
                    document.getElementsByTagName("html")[0].setAttribute("class", "");
                    document.getElementsByTagName("body")[0].setAttribute("class", "");
                }
            }
        },

        mounted() {
            require('./javascript');
            require('./swipers');
            require('./parallax');
        }
    });
};
// Execute async function
main().then( (value) => {

});
